<template>
  <FormModal
    title="Geben Sie Ihre Daten ein"
    next-btn-text="Jetzt vergleichen"
    show-back-btn
  >
    <template #body>
      <ContactInformationFormStep :progress-value="progressValue" />
    </template>
  </FormModal>
</template>

<script>
import FormModal from 'chimera/all/themes/blueflow/components/layout/FormModal'
import AbstractFormPage from 'chimera/all/components/page/AbstractFormPage'
import ContactInformationFormStep from '~/components/form/steps/contactInformation/ContactInformationFormStep'

export default {
  components: {
    ContactInformationFormStep,
    FormModal,
  },

  extends: AbstractFormPage,

  layout: 'form',

  /**
   * @returns {object}
   */
  data() {
    return {
      headTitle: 'Deine Daten',
      headDescription:
        'Geben Sie Ihre Daten ein, damit wir Sie an den am besten geeigneten Solarmodullieferanten in Ihrer Region weiterleiten können.',
      path: '/angebotsanfragen/ihre-daten',
      progressValue: 75,
      checkoutStep: 3,
    }
  },
}
</script>
